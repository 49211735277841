<template>
    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4 mb-5">
        <div v-if="!isLoad" class="row">
            <div class="col-12">
                <div v-if="data.length" class="row">
                    <div class="col-12">
                        <splide :options="slide">
                            <splide-slide class="text-center h-auto rounded-lg mx-3" v-for="(item, n) in data.filter(x => x.user != null)" :key="n">
                                <div class="card pt-4 p-md--3 text-center shadow rounded-lg mb-3 h-auto">
                                    <a href="javascript:void(0)" @click="setCurrent(item)" class="card-title">
                                        <img class=" rounded-circle" :src="item.user.photo ? item.user.photo :'/images/profile.png'" width="100px" alt="">
                                    </a>
                                    <div class="card-body mb-2">
                                        <h5 class="text-grey-900 fw-700 text-center mt-0 pt-1">{{item.user.name}}</h5>
                                        <span v-if="item.score != null" class="py-1 px-2 badge badge-primary mr-2">Score : {{item.score | numFormat('0,0')}}</span>
                                        <span v-if="item.score != null" class="py-1 px-2 badge badge-warning text-white" style="cursor: pointer" @click="resetSubmission(item)">Reset</span>
                                    </div>
                                </div>
                            </splide-slide>
                        </splide>
                    </div>
                    <div v-if="current != null" class="col-12 mt-5">
                        <div class="tab-content">
                            <div class="tab-pane fade show active">
                                <div class="row">
                                    <div class="col-12 mt-4">
                                        <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden mb-4">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-lg-6">
                                                        <apexchart type="bar" :options="current.chart.options" :series="current.chart.series"></apexchart>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <h4 class="text-grey-800 font-xs mt-4 mb-3 fw-700">Indicator :</h4>
                                                        <p class="mb-1" v-for="(items, indexs) in current.indicator" :key="indexs">The number of searches for the circled <b>{{ indexs }}</b> answers = <b>{{ items }}</b></p>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12" v-for="(item, index) in current.study_type" :key="index">
                                                        <LearningStyle :type="item" :indicator="current.indicator"></LearningStyle>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12" v-if="current.answer.length">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden mb-4">  
                                                    <div class="card-body">
                                                        <div class="row">
                                                            <div class="col-12 mb-2">
                                                                <h4>Questions</h4>
                                                            </div>
                                                            <div class="col-12">
                                                                <button v-for="(item, i) in current.answer" :disabled="disButton" :key="i" @click="setAnswer(i)" :class="{'btn-success' : answer == i && item.corect == 1, 'btn-outline-success' : answer != i && item.corect == 1, 'btn-danger' : answer == i && item.corect == 0, 'btn-outline-danger' : answer != i && item.corect == 0}" class="btn w50 h50 col mr-2 mb-2 ">{{i + 1}}</button>
                                                            </div>
                                                        </div>
                                                    </div>  
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="card p-0 mb-5">
                                                    <div class="row" v-for="(item, index) in current.answer" :key="index">
                                                        <div class="col-12" v-show="answer == index">
                                                            <div class="card-body p-0">
                                                                <div :id="'accordion1-'+index" class="accordion mb-0">
                                                                    <div class="card shadow-xss mb-0">
                                                                        <div class="card-header border-radius-none bg-current" :id="'heading1-'+index">
                                                                            <h5 class="mb-0"><button class="btn font-xsss fw-600 btn-link text-white collapsed" data-toggle="collapse" :data-target="'#collapse1-'+index" aria-expanded="false" :aria-controls="'collapse1-'+index">Question - {{index+1}}</button></h5>
                                                                        </div>
                                                                        <div :id="'collapse1-'+index" class="collapse" :aria-labelledby="'heading1'+index" :data-parent="'#accordion1-'+index">
                                                                            <div class="card-body text-center" v-if="item.quest.quest_type == 1">
                                                                                <video class="w-50 h-auto" controls>
                                                                                    <source :src=" item.quest.path" type="video/mp4">
                                                                                </video>
                                                                            </div>
                                                                            <div class="card-body" v-if="item.quest.quest_type == 2">
                                                                                <audio class="w-100" controls>
                                                                                    <source :src=" item.quest.path" type="audio/mpeg">
                                                                                </audio>
                                                                            </div>
                                                                            <div class="card-body ql-editor">
                                                                                <div v-html="item.quest.question"></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="card-body p-0">
                                                                <div :id="'accordion2-'+index" class="accordion mb-0">
                                                                    <div class="card shadow-xss mb-0">
                                                                        <div class="card-header bg-greylight theme-dark-bg" :id="'heading-2'+index">
                                                                            <h5 class="mb-0"><button class="btn font-xsss fw-600 btn-link collapsed" data-toggle="collapse" :data-target="'#collapse2-'+index" aria-expanded="false" :aria-controls="'collapse2-'+index">Answer</button></h5>
                                                                        </div>
                                                                        <div :id="'collapse2-'+index" class="collapse" :aria-labelledby="'heading2-'+index" :data-parent="'#accordion2-'+index">                
                                                                            <div style="width: 100wh" class="card-body p-0 border-top bg-lightgrey">
                                                                                <table class="table m-0">
                                                                                    <tr v-for="(opt, j) in item.quest.option" :key="j">
                                                                                        <td width="5%" v-if="opt.is_answer" class="bg-success text-white"><i v-if="opt.sub_answer" class="fas fa-check"></i></td>
                                                                                        <td width="5%" v-else class="bg-grey text-success"><i v-if="opt.sub_answer" class="fas fa-check"></i></td>
                                                                                        <td>{{opt.option}}</td>
                                                                                    </tr>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="row">
                    <div class="col-12 text-center">
                        <img :src="'/images/empty.png'" alt="" width="300">
                        <h1 class="text-muted">Data don't exist</h1>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="row">
            <div class="col-12 text-center py-3">
                <div class="my-3">
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import LearningStyle from '../../../../Core/Getting Started/Sub/Wa/LearningStyle.vue'
export default {
    components: {
        LearningStyle
    },
    data(){
        return {
            id: this.$route.params.idWa,
            media : process.env.VUE_APP_URL_CLOUD,
            isLoad: true,
            data: [],
            files: [],
            form: {
                rubrics:{
                    id: [],
                    value:[],
                }
            },
            agree: false,
            current: null,
            answer: 0,
            disButton : false,
            slide: {
                perPage : 3,
                gap : '2rem',
                arrows : true,
                pagination : false,
                breakpoints: {
                    '800' : {
                        perPage: 2,
                        gap: '10rem'
                    },
                    '640' : {
                        perPage: 1,
                        gap: '4rem'
                    },
                    '480' : {
                        perPage: 1,
                        gap: '2rem'
                    },
                }
            },
            content:''
        }
    },
    created(){
        this.getSubmission()
    },
    methods: {
        setItemRef(i) {
            return `upload${i}`;
        },
        isActive(url) {
            var path = this.$route.path.split('/')
            return url == path[0]
        },
        setCurrent(data){
            this.current = data
            this.setAnswer(0)
            this.files = []
            this.agree = false
        },
        setAnswer(i) {
            this.answer = i
        },
        async getSubmission(){
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/setting/getting-start/wa/submission?id=${this.id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if (res.data.success){
                    this.data = res.data.data
                    this.isLoad = false
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Submission!',
                        text: res.data.message,
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
                
            }).catch((err) => console.error(err.response))
        },
        resetSubmission(item) {
            this.$swal({
                title: 'Are you sure?',
                text: 'This person can be submit this test again',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCloseButton: true,
                showLoaderOnConfirm: true,
                preConfirm: async() => {
                    var data = {
                        _method: 'post'
                    }
                    await axios.post(`${process.env.VUE_APP_URL_API}/admin/setting/getting-start/wa/reset-submission/${item.id}`, data, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.token}`
                        },
                    }).then(() => {
                        this.getSubmission()
                    }).catch(() => {
                        this.getSubmission()
                    })
                },
            }).then((result) => {
                if(result.isConfirmed) {
                    this.$swal({
                        toast: true,
                        title: 'WA',
                        text: 'This data already reset !',
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        }
    }
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.table-content{
    max-height: 50vh;
    max-width: 100%;
    overflow: auto;
}

.table {
    min-width: 100wh;
}
</style>